import React from 'react'
import { View, ViewProps } from 'react-native'
import useBreakPoint from './useBreakPoint'
import useBreakPointValue from './useBreakPointValue'

export default function Row(props: ViewProps) {
  const point = useBreakPoint()
  const marginHorizontal = -useBreakPointValue(point, { xs: 5, sm: 5 })

  return (
    <View
      style={[
        { flexDirection: 'row', flexWrap: 'wrap', marginHorizontal },
        props.style,
      ]}
    >
      {props.children}
    </View>
  )
}
