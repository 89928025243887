import { api, colors, store, translate, utils } from '@pay24/common'
import { Toggle } from '@ui-kitten/components'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { confirmAlert } from '../alert'
import { SecondaryButton } from '../Button'
import { FrameContainer } from '../Components'
import Spacer from '../Spacer'
import { Text } from '../Text'
import Password from './Password'
import { Pincode } from './Pincode'
import { PageName, PAGES } from './types'

const { useTranslation } = translate
const { LocalStorage, RootStore } = store
const { charge24 } = api
const { isMobile, isCharge24, isEvion, DeviceInfo } = utils
const { touchIdIsSupported } = DeviceInfo

const isCharge = isCharge24 || isEvion

const Security = <T extends () => void>({
  page,
  onPressBack,
  openPage,
}: {
  page: PageName
  onPressBack: T
  openPage(value: { screen: PageName }): void
}) => {
  const [authWithTouchId, setAuthWithTouchId] = useState(false)
  const [biometryType, setBiometryType] = useState()
  const { t } = useTranslation()

  async function init() {
    try {
      const biometry: any = await touchIdIsSupported()
      setBiometryType(biometry)
      const auth = await LocalStorage.get('authWithTouchId', null)
      setAuthWithTouchId(auth)
    } catch (e) {}
  }

  useEffect(() => {
    init()
  }, [])

  const onCheckedChange = async () => {
    await LocalStorage.set('authWithTouchId', !authWithTouchId)
    setAuthWithTouchId(!authWithTouchId)
  }

  const deleteUser = useCallback(() => {
    confirmAlert(
      t('confirmation'),
      t('delete_user_alert'),
      t('cancel_to'),
      t('yes'),
    ).then(() =>
      charge24.deleteUser().then(() => {
        onPressBack()
        RootStore.logout()
      }),
    )
  }, [onPressBack, t])

  if (page === PAGES.security) {
    return (
      <>
        <FrameContainer
          icon="lock-outline"
          iconBg={colors.blue}
          title="password"
          subTitle={'change_password'}
          onPress={() =>
            openPage({
              screen: PAGES.password,
            })
          }
          arrow
        />
        <Spacer />
        {isMobile && !isCharge ? (
          <FrameContainer
            icon="dialpad"
            iconBg={colors.coal}
            title="pincode"
            subTitle={'change_pin'}
            onPress={() =>
              openPage({
                screen: PAGES.pinCode,
              })
            }
            arrow
          />
        ) : null}
        <Spacer />
        {isMobile && biometryType && !isCharge ? (
          <FrameContainer
            icon={
              biometryType === 'FaceID' ? 'face-recognition' : 'fingerprint'
            }
            iconBg={colors.brown}
            title={biometryType === 'FaceID' ? 'Face id' : 'Touch id'}
            subTitle={'enable_biometrics'}
            rightComponent={
              <Toggle
                checked={authWithTouchId}
                onChange={onCheckedChange}
                style={{ marginRight: 15 }}
              />
            }
            arrow
          />
        ) : null}
        {isCharge ? (
          <>
            <Text size={16} color={colors.metal}>
              {t('delete_user_sub')}
            </Text>
            <Spacer />
            <SecondaryButton title="delete_user" onPress={deleteUser} />
          </>
        ) : null}
      </>
    )
  }
  if (page === PAGES.password) return <Password setMain={onPressBack} />
  if (page === PAGES.pinCode) return <Pincode setMain={onPressBack} />
  return null as never
}

export default observer(Security)
