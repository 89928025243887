import requester from '../api/requester'
import { sleep } from '../utils'

const requestPost = (url, data, silent = false) => {
  return requester.post(url, data, silent, 'charge24')
}

const requestGet = (url, params = {}, silent = false) => {
  return requester.get(url, params, silent, 'charge24')
}

const requestDelete = (url, data, silent = false) => {
  return requester.delete_(url, data, silent, 'charge24')
}

interface KaspiPayReponse {
  code: number
  message: string
  redirectUrl: string
  txn_id: string
}

interface KaspiCheckStatusReponse {
  status: 'success' | 'pending' | 'fail'
}

let cancelPayment = true

const charge24Api = {
  getTransInfo: function (id) {
    return requestGet(`admin/get_transaction/${id}`)
  },
  getTransaction: function (transaction_id: number) {
    return requestPost('cp/transaction_by_id', { transaction_id })
  },
  getInfoPoint: function (number) {
    return requestPost('cp/points_info', { numbers: number })
  },
  getMyPoints: function () {
    return requestGet('cp/my-points')
  },
  getNearestPoint: function (data) {
    return requestPost('cp/nearest-points', data)
  },
  getBalance: function () {
    return requestPost('cp/balance', {})
  },
  addFavorite: function (data) {
    return requestPost('cp/favorite', data)
  },
  getFavorite: function () {
    return requestGet('cp/favorite')
  },
  getReport: function (data) {
    return requestPost('admin/report', data)
  },
  getReportByCountry: function (data) {
    return requestPost('admin/report/users_country_charging_points', data)
  },
  getPointList: function (data) {
    return requestPost('point_list', data)
  },
  getRecon: function (data) {
    return requestPost('admin/recon_act', data)
  },
  getReconData: function (data) {
    return requestPost('admin/report_by_day', data)
  },
  getReportExcel: function (data) {
    return requestPost('cp/report_excel', data)
  },
  getReportExcelAdmin: function (data) {
    return requestPost('admin/report_excel', data)
  },
  async downloadReportExcel(filename: string) {
    return requester.get(
      'admin/get_file/report',
      { filename },
      false,
      'charge24',
      null,
      {
        responseType: 'blob',
      },
    )
  },
  debitAccountAddStart(type, data) {
    return requestPost(`acquiring/auth`, { id: type, ...data })
  },
  getLastRecords(data) {
    return requestPost('acquiring/history/last', data, true)
  },
  getCurrencies() {
    return requestGet('admin/exchange_rate')
  },
  getMonitoring: function (data = {}) {
    return requestPost('admin/monitoring', data)
  },
  runPoint: function (data = {}) {
    return requestPost('admin/run_point', data)
  },
  stopPoint: function (data = {}) {
    return requestPost('admin/stop_point', data)
  },
  resetPoint: function (data = {}) {
    return requestPost('admin/point_reset', data)
  },
  controlConnector: function (data = {}) {
    return requestPost('admin/connector_control', data)
  },
  getPointsService: function () {
    return requestGet('cp/')
  },
  paymentCheck: function (data = {}, silent = false) {
    return requestPost('acquiring/check', data, silent)
  },
  paymentStart: function (data = {}, silent = false) {
    return requestPost('acquiring/start', data, silent)
  },
  paymentPoll: async function (id) {
    cancelPayment = false
    let data: any = null
    do {
      await sleep(3000)
      data = await requestGet(`acquiring/status/${id}`, {}, true)
    } while (!cancelPayment && data?.status === 'pending')
    return data
  },
  cancelPaymentPoll() {
    cancelPayment = true
  },
  getDebitAccounts: function (data = {}) {
    return requestPost('acquiring/accounts', data)
  },
  addUser: function (data) {
    return requestPost('admin/user', data)
  },
  addPoint: function (data) {
    return requestPost('admin/point', data)
  },
  getUsers: function (data) {
    return requestPost(`admin/users`, data)
  },
  getUsersCorporate: function (data) {
    return requestPost('admin/users_corporate', data)
  },
  updateCorporateSource: function (source: string) {
    return requestPost('cp/default_source', { balance_type: source })
  },
  getUsersForCorporate: function (phone_number: string) {
    return requestPost('corporate/available_user', { phone_number })
  },
  getCorporateUsers: function (search = '') {
    return requestGet('corporate/user', { search })
  },
  addCorporateUser: function (data) {
    return requestPost('corporate/user', data)
  },
  deleteCorporateUser: function (user_id) {
    return requestDelete('corporate/user', { user_id })
  },
  getUserById: function (id) {
    return requestPost('admin/get_user', { user_id: id })
  },
  getUser: function () {
    return requestGet(`admin/user`)
  },
  deleteUser: function () {
    return requestPost('admin/del_user', {})
  },
  updatePhoneNumber: function () {
    return requestPost('cp/update_phone', {})
  },
  getMappedUsers: function (data) {
    return requestPost(`admin/mapped_user`, data)
  },
  deleteMappedUser: function (data) {
    return requestPost(`admin/rm_mapped_user`, data)
  },
  getPoints: function (data) {
    return requestPost(`admin/points`, data)
  },
  getBlockedPoints: function (params) {
    return requestPost(`admin/points?status=${params}`, {})
  },
  startCharging: function (data) {
    return requestPost('cp/start-charging', data)
  },
  stopCharging: function (data) {
    return requestPost('cp/stop-charging', data)
  },
  updateCurrency: function (currency: string) {
    return requestPost('cp/currency', { currency })
  },
  getBalanceHistory: function () {
    return requestPost('cp/balance/history', {})
  },
  getHistoryBalance: function (data = {}) {
    return requestPost('cp/replenishment_history', data)
  },
  getHistoryAccount: function (data) {
    return requestPost('acquiring/history', data)
  },
  chargePaymentTransfer: function (data) {
    return requestPost('admin/payment_transfer', data)
  },
  getHistoryAccountOne: function (id) {
    return requestGet(`acquiring/history/${id}`)
  },
  addBrand: function (data) {
    return requestPost('admin/brand', data)
  },
  getBrand: function (agent_id, status = 'all') {
    return requestGet(`admin/brand?agent_id=${agent_id}&status=${status}`)
  },
  getBrands: function () {
    return requestPost('admin/brands', {})
  },
  deleteBrand: function (id) {
    return requestPost('admin/del_brand', { id })
  },
  merchantSelect: function (data) {
    return requestPost('admin/merchant_select', data)
  },
  showPointMerchant: function (data) {
    return requestPost('cp/merchant_visibility', data)
  },
  getStatisticAdmin: function (data) {
    return requestPost('admin/statistics_by_point', data)
  },
  getStatistic: function (data) {
    return requestPost('admin/statistics_by_point/user', data)
  },
  kaspiPay: function (data: {
    amount: number
    requisite: string
  }): Promise<KaspiPayReponse> {
    return requestPost('cp/kaspi/pay', data)
  },
  berekePay: function (data) {
    return requestPost('acquiring/auth', data)
  },
  kaspiCheckStatus: function (
    txn_id: string,
  ): Promise<KaspiCheckStatusReponse> {
    return requestPost('cp/kaspi/status', { txn_id }, true)
  },
  depositUser: function (account, amount) {
    return requestPost('admin/refill_balance', { account, amount })
  },
  getFilters: function () {
    return requestGet('filter_points')
  },
  refillBalance: function (amount: number) {
    return requestPost('corporate/refill', { amount })
  },
  corporateReport: function (data) {
    return requestPost('corporate/report', data)
  },
  finePoint: function (data) {
    return requestPost('cp/assign_fee', data)
  },
  addStory: function (data) {
    return requestPost('cp/add_story', data)
  },
  getStories: function (data) {
    return requestPost('open/stories', data, true)
  },
}

export default charge24Api
